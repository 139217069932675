<template>
  <div class="w-100">
    <div class="overflow-auto" style="max-height: 400px">
      <div class="d-flex justify-content-start py-2">
        <h5>추천 이력</h5>
      </div>
      <div v-if="pending">
        <b-spinner variant="danger" label="LOADING" />
      </div>
      <div v-else>
        <hr/>
        <div class="overflow-auto">
          <div class="text-left"
              v-for="r in recommenders" :key="`login-history-${r._id}`"
          >
              {{ r.userId }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recommender",
  props: {
    selectedId: String
  },
  data: () => ({
    pending: false,
    recommenders: []
  }),
  created () {
    (async () => {
      await this.load()
    }) ()
  },
  methods: {
    async load () {
      this.pending = true
      try {
        const url = `/admin/users/recommender/${this.selectedId}`
        const { data } = await this.axios({
          url
        })

        const { result, recommenders, error } = data
        if (result) {
          this.recommenders = recommenders
        }
      } catch (e) {
      }
      this.pending = false
    }
  }
}
</script>

<style scoped>

</style>
